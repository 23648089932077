<template>
    <user-dashboard-layout>
        <v-card class="mx-auto" :loading="loading">
            <v-card-text>
                <v-row class="mb-5">
                    <v-col v-if="activePricePlan && activePricePlan.name">
                        Your Plan is: {{ activePricePlan.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            :to="{name: 'dashboard.billing.select-plan'}"
                            v-text="'Select Plan'"
                        />
                    </v-col>
                </v-row>

                <stripe-checkout-card @submit-card="savePaymentMethod"/>

                <div class="mt-15">
                    <div v-if="!paymentMethods.length">
                        No payment method found, please add a payment method.
                    </div>
                    <div v-else>
                        <div v-for="(method, key) in paymentMethods"
                             @click="paymentMethodSelected = method.id"
                             :key="key"
                             :class="{'bg-success text-light': paymentMethodSelected == method.id}"
                             class="border rounded row p-1"
                        >
                            <div class="col-2">
                                {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                            </div>
                            <div class="col-7">
                                Ending In: {{ method.last_four }} Exp: {{ method.exp_month }} / {{ method.exp_year }}
                            </div>
                            <div class="col-3">
                                <v-btn
                                    @click.stop="removePaymentMethod( method.id )"
                                    depressed
                                    :disabled="loading"
                                    :loading="loading"
                                    v-text="'Remove'"/>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>

        </v-card>
    </user-dashboard-layout>
</template>

<script>
    import BillingApi from "../../../api/BillingApi";
    import StripeCheckoutCard from "../../../components/StripeCheckoutCard";
    import { mapMutations} from "vuex";
    import PricePlan from "../../../models/PricePlan";
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";

    export default {
        name: 'Index',
        components: {
            StripeCheckoutCard,
            UserDashboardLayout
        },
        data() {
            return {
                paymentMethods: [],
                paymentMethodSelected: {},
                activePricePlan: {},
                loading: false,
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            async fetch() {
                this.loading = true;

                try {
                    await this.loadPaymentMethods();

                    if (this.$auth.user().price_plan_id) {
                        this.activePricePlan = await PricePlan.find(this.$auth.user().price_plan_id)
                    }
                } catch (e) {
                    this.showSnackBar({color: 'error', timeout: 3000, text: e.response?.message || e})
                } finally {
                    this.loading = false;
                }
            },

            async loadPaymentMethods(){
                this.paymentMethods = await BillingApi.paymentMethods()
            },

            removePaymentMethod(methodStripeId) {
                this.loading = true;

                BillingApi.removePaymentMethod(methodStripeId)
                    .then(() => {
                        this.loadPaymentMethods()
                        this.showSnackBar({
                            color: 'success',
                            timeout: 3000,
                            text: 'Billing Method Removed Successfully'
                        })
                    })
                    .catch((e) => this.showSnackBar({ color: 'error', timeout: 3000, text: e.response?.message || e }))
                    .finally(() => this.loading = false)
            },

            savePaymentMethod( method ){
                window.axios.post('/api/billing/payments', {
                    payment_method: method
                }).then( () => {
                    this.showSnackBar({color: 'success', timeout: 3000, text: 'Billing Method Added Successfully'})
                    this.loadPaymentMethods()
                })
            },
            ...mapMutations(['showSnackBar']),
        }
    }
</script>

<style scoped>

</style>
